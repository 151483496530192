import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeMenuItemModal, openMenuItemModal } from '../../shared/DishActions';
import { getParam } from '../../utils/urls';

import BasicModal from '../../admin/shared/BasicModal';
import MenuItemDetails from './MenuItemDetails';
import { MenuItemQuery } from './MenuItemQuery';

const MenuItemModalContainer = () => {
  const menuItemId = useSelector(state => state.dishes.menuItemId);
  const showMenuItemModal = useSelector(state => state.dishes.showMenuItemModal);
  const menuEnabled = useSelector(state => state.dishes.menuEnabled);
  const menuOrderingUrl = useSelector(state => state.dishes.menuOrderingUrl);
  const scheduledAt = useSelector(state => state.dishes.scheduledAt);
  const dispatch = useDispatch();

  if (!showMenuItemModal) {
    return null;
  }

  const closeModal = () => {
    dispatch(closeMenuItemModal());
    const menuSlug = getParam('menu');
    window.history.replaceState(null, null, menuSlug ? `#menu=${menuSlug}` : '');
  };

  return (
    <BasicModal
      closeButton={false}
      closeModal={closeModal}
      fullScreen
      paper={false}
      PaperProps={{
        'aria-label': 'Dish Details Dialog',
      }}
      show={showMenuItemModal}
      transition="left"
      disableEnforceFocus
    >
      {menuItemId && (
        <MenuItemQuery menuItemId={menuItemId}>
          {({ data, loading }) => (
            <MenuItemDetails
              closeMenuItemModal={closeModal}
              isModal
              loading={loading}
              menuEnabled={menuEnabled}
              menuOrderingUrl={menuOrderingUrl}
              menuItem={data && data.menuItem}
              menuItemId={menuItemId}
              openMenuItemModal={(nextMenuItemId, ...params) => dispatch(openMenuItemModal(nextMenuItemId, ...params))}
              scheduledAt={scheduledAt}
            />
          )}
        </MenuItemQuery>
      )}
    </BasicModal>
  );
};

export default MenuItemModalContainer;
